import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, navigate } from "gatsby";
import { Header } from "../components/Header/Header";
import logoWhite from "../images/logo-white.png";
import LoaderIcon from '../images/loader.svg'

import {
  CircleStackIcon,
  UserPlusIcon,
  MagnifyingGlassCircleIcon,
  AdjustmentsVerticalIcon,
  PencilSquareIcon,
  ArrowLeftCircleIcon
} from '@heroicons/react/24/outline'
import { Footer } from "../components/Footer/Footer";
import { getSeats } from "../helpers/courses";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";

const AssignedStudents = ({ location }: any) => {
  const userName = typeof window !== 'undefined' && localStorage.getItem('name');
  const [signed, setSigned] = useState(false);

  const [courseName, setCourseName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [courseId, setCourseId] = useState("");

  const [users, setUsers] = useState([]);

  const [loading,setLoading] = useState(true);

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (loginElement.items.status) {
      setSigned(true)
    } else {
      navigate("/")
    }
  }, [loginElement]);

  const getSeatsNow = async () => {
    setLoading(true);
    const alumns = await getSeats(location.state.elementItem.courseUuid);
    setUsers(alumns);
    setLoading(false);
  }

  useEffect(() => {
    if (location.state !== null) {
      setCourseName(location.state.elementItem.course.title);
      setQuantity(location.state.elementItem.quantity);
      setCourseId(location.state.elementItem.courseUuid);
      getSeatsNow();
    } else {
      navigate("/courses");
    }
  }, [location])

  return (
    <Layout signed={signed}>
      <div className="bg-[#f2f2f2]">
        <div className="mb-[40px]">
          {/* header */}
          {/* <Header  isSignIn={signed}/> */}

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="lg:flex lg:items-center lg:justify-between pb-10">
              <div className="flex items-center">
                <img src="" alt="" />
                <div className="mb-4 md:mb-0">
                  <div className="flex items-center mb-2">
                    <Link className="flex items-center" to={"/courses"}>
                      <ArrowLeftCircleIcon className="h-6 w-6" />
                      <span className="ff-cg--medium ml-2">BACK TO COURSES</span>
                    </Link>
                  </div>
                  <h2 className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">Assigned Seats</h2>
                  <p className="text-[18px] lg:text-[30px] ff-cg--semibold">Course: {courseName}</p>
                </div>
              </div>
            </div>
            <div className="grid gap-4 lg:gap-10 lg:grid-cols-12">
              <div className="col-span-12">
                <div className="rounded-xl bg-white shadow-lg h-full p-[3px]">
                  <div className="overflow-x-auto">

                    {
                      (users.length) ?
                        <table className="lg:w-full rounded-2xl">
                          <thead className="text-white rounded-2xl  border-[#da1a32]">
                            <tr>
                              <th className="bg-[#da1a32] rounded-tl-xl text-left ff-cg--semibold py-4 px-8">Name</th>
                              <th className="bg-[#da1a32] text-left ff-cg--semibold py-4 px-8">Email</th>
                              <th className="bg-[#da1a32] rounded-tr-xl text-right ff-cg--semibold py-4 px-8"></th>
                            </tr>
                          </thead>
                          <tbody className="">
                            <>
                              {
                                users.map((item: any, index) => {
                                  return (
                                    <tr className="border-b border-solid" key={index}>
                                      <td className="py-4 px-8">{item.profile.name}</td>
                                      <td className="py-4 px-8">{item.profile.email}</td>
                                      <td className="py-4 px-8">
                                        <button className="ml-auto w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                                          <Link to="/edit-student" className="flex"><PencilSquareIcon className="h-6 w-6" />
                                            <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">Remove Seat</span></Link>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </>
                          </tbody>
                        </table> :
                        <div className="w-full h-full flex justify-center pt-20 pb-20">
                        <div className="text-center">
                          {
                            (loading) ?
                            <>
                              <img src={LoaderIcon} className={`w-[120px] h-[120px] mmb-4 inline-block`} />
                              <p className="font-bold text-4xl mb-2 ff-cg--semibold">Loading Results</p>
                              <p className="text-xl">Please wait a moment while we set things up for you!</p>
                            </> :
                            <p className="text-xl">Theres no results for your search</p>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  {/* <div className="flex items-center justify-between py-4 px-8">
                    <p className="ff-cg--semibold">Showing 1 - 5 of 10 Items</p>
                    <div className="flex items-center gap-4">
                      <button className="rounded-md flex items-center justify-center text-[18px] bg-[#da1a32] text-white w-10 h-10">
                        <span className="ff-cg--semibold">1</span>
                      </button>
                      <button className="rounded-md flex items-center justify-center text-[18px] border-2 border-solid border-[#da1a32] text-[#da1a32] w-10 h-10">
                        <span className="ff-cg--semibold">2</span>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default AssignedStudents;
export { Head } from "../components/Layout/Head";
